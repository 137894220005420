.page {
    background-color: #fcf7eb;
    color: #8c8c8c;
    border: solid 1px #c8b8aa;
    overflow: hidden;
}

.page-content {
    width: 100%;
    height: 95%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.page-footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
    height: 5%;
}