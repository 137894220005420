.wordle-board {
    margin: 1rem;
}

.wordle-title {
    font-style: "nyt-karnakcondensed";
    font-size: 25px;
    font-weight: 700;
}

.wordle-elements {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.wordle-word {
    display: flex;
    flex-direction: row;
}

.wordle-cell {
    width: 3rem;
    height: 3rem;
    margin: .2rem;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-family:'Clear Sans', 'Helvetica Neue', Arial, sans-serif
}

.wordle-cell-empty {
    color: black;
    border: solid lightgray 2px;
}

.wordle-cell-correct {
    color: white;
    background-color: #6aaa64;
    border: solid #6aaa64 2px;
}

.wordle-cell-present {
    color: white;
    background-color: #c9b458;
    border: solid #c9b458 2px;

}
.wordle-cell-absent {
    color: white;
    background-color: #787c7e;
    border: solid #787c7e 2px;

}


.wordle-keyboard {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.wordle-keyboard-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.wordle-keyboard-key {
    background-color: lightgray;
    color: black;
    border-radius: 3px;
    border-width: 0;
    width: 2rem;
    height: 3rem;
    font-size: 15px;
    margin: .1rem;
    font-weight: 600;
    cursor: pointer;
}

.wordle-keyboard-key-enter {
    width: 3rem;
    font-size: 10px;
}

.wordle-keyboard-key-backspace {
    width: 3rem;
}