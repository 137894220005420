/* src/App.css */
.game {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
  }
  
  .game-board {
    display: flex;
    flex-direction: column;
  }
  
  .board-row {
    display: flex;
  }
  
  .square {
    width: 100px;
    height: 100px;
    background: #fff;
    border: 1px solid #999;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
  }
  
  .square:disabled {
    cursor: not-allowed;
  }
  
  .square:hover:not(:disabled) {
    background-color: #ddd;
  }
  
  .square img.piece {
    max-width: 80%;
    max-height: 80%;
    opacity: 0;
    animation: fadeIn 0.5s forwards;
  }
  
  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }
  
  .game-info {
    margin-top: 20px;
    display:flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .game-info button {
    margin-top: 10px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .game-info button:hover {
    background-color: #45a049;
  }
  