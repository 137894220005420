.title {
    font-size: 3rem;
    margin: 2rem;
    color: white;
    font-family: 'Clear Sans', 'Helvetica Neue', Arial, sans-serif;
}

.page-cover {
    background-color: #05687e;
    color: #8c8c8c;
    border: solid 1px #c8b8aa;
    overflow: hidden;
}