
.image {
    width: 95%;
    margin:1rem 0 0 0;
}

.image-container {
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10%;
    margin: 0 10px 0 10px;
    font-family: 'Clear Sans', 'Helvetica Neue', Arial, sans-serif;
}